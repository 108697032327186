.questionnaireList {
    margin: 0 0 35px;
}

.questionnaire__field {
    font-size: calc(0.8 * var(--size-index));
}

.questionnaire__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questionnaire__field:first-child {
    word-wrap: break-word;
    width: 75%;
}

.questionnaireList__button {
    margin: 15px auto 0;
    display: block;
}

.questionnaireList__adder {
    border-radius: 20px;
    padding: 15px 25px;
    margin: 15px auto 0;
    display: block;
}

@media screen and (max-width: 800px) {
    .questionnaire__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .questionnaire__field {
        width: fit-content;
    }

    .questionnaire__field:first-child {
        font-size: calc(1 * var(--size-index));
        margin-bottom: 15px;
        width: 100%;
    }

    .questionnaire__field {
        font-size: calc(1 * var(--size-index));
    }

    .recordList__searcher {
        width: 50%;
    }

    .recordList__input {
        border-radius: 20px;
        padding: 8px 20px 8px 40px;
        font-size: calc(1 * var(--size-index));
    }

    .record__button {
        margin-top: 30px;
    }

    .recordList__icon {
        top: 8px;
        left: 12px;
    }
}