.table {
    margin-top: 25px;
    border-radius: 20px;
    overflow: hidden;
    height: fit-content;
    border: 1px solid var(--secondary-color);
    border-bottom: 5px solid var(--secondary-color);
}

.table__head {
    display: flex;
    justify-content: space-around;
    background-color: var(--secondary-color);
    padding: 5px 0;
    padding-left: 25px;
}

.table__body {
    margin-top: 0;
    color: var(--font-color);
    min-height: 40vh;
    max-height: 80vh;
    overflow-y: auto;
}

.table__head .table__field {
    font-size: calc(0.8 * var(--size-index));
    font-weight: bold;
    color: var(--light-color);
}

.table__field, .table__record p, .table__record div {
    flex: 1 1 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table__record div {
    justify-content: space-around;
}

.table__record {
    display: flex;
    position: relative;
    padding: 25px 0 25px 10px;
    border-bottom: 1px solid var(--border);
}

.table__record .index {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: calc(0.96 * var(--size-index));
    font-weight: 600;
    font-style: normal;
    left: 10px;
}

.table__record button {
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    height: calc(2.6 * var(--size-index));
    min-width: calc(2.6 * var(--size-index));
    font-weight: 400;
    font-size: calc(0.8 * var(--size-index));
    padding: 0 calc(1 * var(--size-index));
    cursor: pointer;
}

.table__record button img {
    width: 70%;
    height: 70%;
}


.table__record button.icon {
    padding: 0;
    width: calc(2.6 * var(--size-index));
}

.table__empty {
    font-size: calc(3 * var(--size-index));
    color: var(--secondary-color);
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table__record .label {
    display: none;
}

@media screen and (max-width: 800px) {
    .table {
        border: 0;
    }

    .table__head {
        display: none;
    }

    .table__record .label {
        display: block;
    }

    .table__record div, .table__record p{
        justify-content: space-between;
    }

    .table__record {
        padding: 12px 0 12px 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8vw;
        row-gap: 14px;
    }

    .table__record div {
        grid-column: 1/3;
        flex-direction: row-reverse;
    }

    .table__record .index {
        top: 15px;
        transform: translate(0, 0);
    }
}