.auth__form {
    margin: 0 auto 20px;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth__title {
    text-align: center;
    font-size: calc(1.04 * var(--size-index));
}

.auth__controlls {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 25px 0 50px;
}

.auth__input {
    display: block;
    width: 100%;
    margin-top: 8px;
    padding: 12px 25px;
    background: var(--primary-color);
    border-radius: 20px;
    font-size: calc(0.77 * var(--size-index));
    color: var(--light-color);
}

.auth__label {
    margin-bottom: 20px;
    font-size: calc(0.77 * var(--size-index));
    color: var(--secondary-color);
}

.auth__button {
    background-color: var(--secondary-color);
    color: var(--light-color);
}

@media screen and (max-width: 800px) {

    .auth__form  {
        width: 80%;
    }

    .auth__title {
        font-size: calc(1.5 * var(--size-index));
    }

    .auth__label {
        margin-bottom: 25px;
        font-size: calc(1 * var(--size-index));
    }

    .auth__input {
        margin-top: 5px;
        padding: 12px 17px;
        font-size: calc(1 * var(--size-index));
    }

    .auth__form {
        width: 80%;
    }

    .auth__controlls {
        margin: 20px 0 30px;
    }
}

@media screen and (max-width: 400px) {
    .auth__form {
        width: 100%;
    }
}