header {
    position: relative;
    z-index: 10;
    background-color: var(--paper);
}

.header__inner {
    display: flex;
    justify-content: space-between;
}

.header__preview {
    display: flex;
    align-items: center;
    padding: 33px 0;
    width: 40%;
}

.header__logo {
    margin-right: 20px;
    width: 72px;
    height: 103px;
}

.header__title {
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.65 * var(--size-index));
}

.header__navbar {
    display: flex;
}

.header__navlist {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header__navitem {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header__navitem a, .header__navitem--icon a {
    text-align: center;
    width: max-content;
    padding: 0 17px;
    text-decoration: none;
    font-size: calc(0.65 * var(--size-index));
    width: 100%;
}

.header__navitem--icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: max-content;
    cursor: pointer;
}

.header__cross {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    display: none;
    background-color: transparent;
}

.header__burger {
    display: none;
    background-color: transparent;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .header__title {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .header__navbar, .header__navbar--shown {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 2;
    }

    .header__navbar--shown {
        display: block;
    }

    .header__navlist {
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        background: var(--paper);
        margin-top: 0px;
    }

    .header__navitem, .header__navitem--icon  {
        justify-content: center;
        width: 100%;
    }

    .header__navitem a , .header__navitem--icon a {
        padding: 20px 0;
        font-size: calc(1.2 * var(--size-index));
    }

    .header__preview {
        padding: 20px 0;
        width: unset;
    }

    .header__title {
        display: block;
    }

    .header__logo {
        margin-right: 20px;
        width: 40px;
        height: 56px;
    }

    .header__burger {
        display: block;
    }

    .header__cross {
        display: block;
    }
}

