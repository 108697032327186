.customerList {
    margin: 0 0 35px;
}

.customer__content {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer__field {
    max-width: 50%;
    font-size: calc(0.8 * var(--size-index));
}

.customer__button {
    margin: 15px auto;
    display: block;
}

.customerList__adder {
    margin: 0 auto 15px;
    display: block;
    border-radius: 20px;
    padding: 15px 25px;
}

@media screen and (max-width: 800px) {
    .customer__content {
        align-items: flex-start;
        width: 100%;
        padding: 15px 0 35px;
    }

    .customer__field:last-child {
        margin-right: 5px;
    }

    .customer__field {
        font-size: calc(1 * var(--size-index));
        margin-right: 5px;
    }
}