.manualRecord {
    padding-bottom: 50px;
}

.view__toggler {
    justify-content: center;
    margin-bottom: 15px;
}

.manualRecord .filler__controlls {
    margin-top: 45px;
}
