:root {
    --font-color: #373434;
    --primary-color: #52766A;
    --secondary-color: #795B4B;
    --paper: #F9F1E7;
    --light-color: #FFFEF9;
    --size-index: min(26px, calc(1.63vw + 6.5px));
    --border: rgba(121, 91, 75, 0.5);
    --error: #7C2A2A;
    --error-transparent: #7c2a2a2f;
}