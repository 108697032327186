footer {
    background-color: var(--secondary-color);
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__preview {
    display: flex;
    align-items: center;
    padding: 33px 0;
}

.footer__title {
    font-style: normal;
    font-weight: 600;
    font-size: calc(0.65 * var(--size-index));
    text-transform: uppercase;
    color: var(--light-color);
}

.footer__logo, .footer__logo--album {
    margin-right: 20px;
    width: 72px;
    height: 103px;
}

.footer__logo--album {
    width: 100px;
    height: 75.8px;
}

@media screen and (max-width: 800px) {
    .footer__inner {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer__preview {
        padding: 10px 0;
    }

    .footer__logo {
        margin-right: 20px;
        width: 40px;
        height: 56px;
    }

    .footer__logo--album {
        margin-right: 20px;
        width: 55px;
        height: 41.2px;
    }
}