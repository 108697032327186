.questionnaireCreator {
    margin: 0 0 50px;
}

.questionnaireCreator__data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.questionnaireCreator__label {
    display: flex;
    font-size: calc(0.92 * var(--size-index));
    align-items: center;
}

.number {
    flex-direction: column;
    align-items: flex-start;
}

.questionnaireCreator__title {
    width: 70%;
    margin: 0 0 15px;
    min-height: calc(5 * var(--size-index));
    font-size: calc(0.92 * var(--size-index));
    background-color: transparent;
    resize: vertical;
    border: 0;
}

.questionnaireCreator__date {
    font-size: calc(0.92 * var(--size-index));
    width: calc(8 * var(--size-index));
    margin-left: 5px;
    background-color: transparent;
    height: 100%;
}

.number .questionnaireCreator__date {
    height: unset;
    margin: 10px 0 0;
}

.questionnaireCreator__date:focus {
    outline: none;
}

.questionnaireCreator__title:focus {
    outline: none;
}

.questions__title {
    text-align: center;
    font-size: calc(1.04 * var(--size-index));
    margin-bottom: 15px;
}

.questions__question {
    display: flex;
    padding: 20px 0;
    align-items: center;
}

.questions__number {
    margin-right: 10px;
    padding-top: 10px;
    align-self: flex-start;
    font-size: calc(0.84 * var(--size-index));
}

.qestions__name {
    width: 60%;
    height: calc(2.07 * var(--size-index));
    font-size: calc(0.84 * var(--size-index));
    background-color: transparent;
    resize: vertical;
}

.qestions__name:focus {
    outline: none;
}

.qestions__type {
    margin-left: auto;
}

.questions__remover {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.questions__remover img {
    width: 70%;
    height: 70%;
}

.qestions__type {
    background-color: transparent;
    border-radius: 1px;
    font-size: calc(0.84 * var(--size-index));
    outline: none;
    box-shadow: none;
}

.questions__remover {
    background-color: var(--primary-color);
    border-radius: 15px;
    width: calc(2.7 * var(--size-index));
    height: calc(2.7 * var(--size-index));
}

.questionnaireCreator__adder {
    background-color: var(--secondary-color);
    border-radius: 20px;
}

.questionnaireCreator__controlls {
    width: fit-content;
    margin: 42px auto 0;
    display: flex;
    justify-content: space-between;
}

.questions__content {
    width: 100%;
    display: flex;
    align-items: center;
}

.questionnaireCreator__button:first-child {
    margin-right: 20px;
}

.questionnaireCreator__button {
    border-radius: 20px;
}

.toggler-container {
    margin: 10px auto 25px;
    width: 25%;
}

.toggler {
    width: 100%;
    border-radius: 15px;
    background-color: var(--primary-color);
    padding: 5px;
    display: flex;
}

.toggler button {
    border-radius: 15px;
    width: 50%;
    padding: 15px;
    text-align: center;
    background-color: transparent;
    color: var(--light-color);
    font-size: calc(var(--size-index) * 0.8);
}

.toggler button.active {
    background-color: var(--light-color);
    color: var(--font-color);
}

@media screen and (max-width: 800px) {
    .questions__title {
        font-size: calc(1.3 * var(--size-index));
        margin-bottom: 25px;
    }

    .questionnaireCreator__data {
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }

    .questionnaireCreator__title {
        width: 100%;
        margin-bottom: 15px;
        text-align: center;
        font-size: calc(1.1 * var(--size-index));
    }

    .questionnaireCreator__label {
        margin-bottom: 15px;
    }

    .questionnaireCreator__date, .questionnaireCreator__label {
        font-size: calc(1.1 * var(--size-index));
    }

    .questions__question {
        border-bottom: 1px solid var(--border);
        padding: 10px 0;
    }

    .questions__number {
        padding-top: 5px;
        font-size: calc(1.1 * var(--size-index));
    }

    .questions__content {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .qestions__name {
        grid-column: 1/3;
        font-size: calc(1.1 * var(--size-index));
        width: 100%;
    }

    .qestions__type {
        margin-right: auto;
        margin-left: 0;
        justify-self: flex-start;
        font-size: calc(1.1 * var(--size-index));
    }

    .questionnaireCreator__adder {
        margin: 20px auto;
        display: block;
    }

    .questionnaireCreator__controlls {
        width: 80%;
        margin-top: 70px;
        justify-content: space-between;
    }

    
}

