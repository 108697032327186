.filler {
    margin: 0 0 50px;
}

.filler__title {
    text-align: center;
}

.filler__description {
    padding: 25px 0;
    font-size: calc(0.88 * var(--size-index));
    text-align: center;
    width: 75%;
    margin: 0 auto;
}

.filler__hint {
    font-size: calc(0.88 * var(--size-index));
}

.filler__content {
    margin: 20px 0 20px;
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filler__individual {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.filler__label {
    font-size: calc(0.88 * var(--size-index));
    margin-bottom: 62px;
    display: flex;
    justify-content: space-between;
}

.filler__input {
    display: block;
    background-color: var(--secondary-color);
    font-size: calc(0.7 * var(--size-index));
    color: var(--light-color);
    padding: 10px 20px;
    border-radius: 20px;
    width: 40%;
}

.filler__controlls {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.filler__button {
    border-radius: 20px;
    margin-right: 5px;
}

.filler__button--small {
    padding: 7px 14px;
    min-width: unset;
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.filler__button:last-child, .filler__button--small:last-child {
    margin-right: 0;
}

.filler__button--small img {
    height: calc(1.8 * var(--size-index));
    width: calc(1.8 * var(--size-index));
}

@media screen and (max-width: 800px) {

    .filler__description {
        font-size: calc(1.1 * var(--size-index));
        width: 100%;
    }

    .filler__hint {
        font-size: calc(1.1 * var(--size-index));
    }

    .filler__content {
        width: 90%;
    }

    .filler__label {
        font-size: calc(1.1 * var(--size-index));
    }

    .filler__controlls {
        width: 85%;
    }

    .filler__button {
        border-radius: 10px;
        padding: 11px 5px;
        width: calc(8 * var(--size-index));
    }

    .filler__button--small {
        border-radius: 10px;
        width: fit-content;
        padding: 11px 10px;
    }
}