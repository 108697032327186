.statistic {
    margin: 0 0 100px;
}

.statistic__description {
    word-wrap: break-word;
    font-size: calc(0.88 * var(--size-index));
    width: 70%;
    text-align: center;
    margin: 22px auto 40px;
}

.filter {
    border-radius: 20px;
    background-color: var(--primary-color);
    padding: 45px;
    width: 70%;
    margin: 0 auto;
}

.filter__title {
    font-size: calc(0.81 * var(--size-index));
    text-align: center;
    color: var(--light-color);
    font-weight: 400;
    margin-bottom: 20px;
}

.filter__form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter__label {
    font-size: calc(0.77 * var(--size-index));
    color: var(--light-color);
    margin-bottom: 35px;
}

.filter__input, .filter__input--both {
    padding: 10px 25px;
    border-radius: 20px;
    background-color: var(--light-color);
    font-size: calc(0.77 * var(--size-index));
    margin-left: 15px;
}

.filter__input--both {
    margin-right: 15px;
}

.filter__button {
    background-color: var(--secondary-color);
    border-radius: 20px;
    width: calc(10.5 * var(--size-index));
    display: flex;
    align-items: center;
}

.filter__button:last-child {
    margin-left: 15px;
}

.statistic__result {
    width: 70%;
    margin: 80px auto 0;
}

.statistic__slice {
    width: 40%;
    text-align: center;
    font-size: calc(0.92 * var(--size-index));
    margin: 0 auto;
}

.statistic__statistic {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.statistic__record {
    margin-bottom: 30px;
}

.statistic__label, .statistic__value,.statistic__value--right {
    font-size: calc(0.77 * var(--size-index));
}

.filter__controlls {
    display: flex;
}

.statistic__label {
    margin-bottom: 10px;
}

.statistic__value--right {
    text-align: end;
}

@media screen and (max-width: 800px) {
    .filter {
        width: 100%;
        padding: 20px;
    }

    .statistic__description {
        width: 100%;
    }

    .filter__input, .filter__input--both {
        width: calc(9 * var(--size-index));
        padding: 10px 15px;
    }

    .filter__button {
        padding: 10px 25px
    }

    .statistic__result {
        margin: 50px 0 0;
        width: 100%;
    }

    .statistic__label, .statistic__value,.statistic__value--right, .filter__input, .filter__input--both {
        font-size: calc(0.9 * var(--size-index));
    }

    .statistic__slice {
        font-size: calc(1 * var(--size-index));
    }

    .statistic__description {
        font-size: calc(1.15 * var(--size-index));
    }
}

@media screen and (max-width: 500px) {
    .filter__input, .filter__input--both {
        width: calc(6 * var(--size-index));
        padding: 5px 10px;
        margin-left: 5px;
    }

    .filter__input--both  {
        margin: 0 5px;
    }

    .filter__label {
        margin-bottom: 15px;
    }

    .filter__button {
        padding: 8px;
    }

    .filter__button:last-child {
        margin-top: 15px;
        margin-left: 0;
    }

    .filter__controlls {
        flex-direction: column;
    }
}