.appeal__select option {
    text-align: end;
}

.appeal__inner {
    width: 60%;
    margin: 0 auto;
}

.appeal__questions {
    width: 100%;
    margin: 24px 0 50px;
}

.question__field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.question__field h3 {
    max-width: 80%;
    word-wrap: break-word;
    font-size: calc(1 * var(--size-index));
}

.question__field i {
    font-size: calc(1 * var(--size-index));
    font-style: normal;
    font-weight: bold;
    color: var(--primary-color);
}

.appeal__value {
    font-size: calc(0.77 * var(--size-index));
    font-weight: 600;
    color: var(--secondary-color);
    background-color: transparent;
    text-align: end;
    width: 60%;
    
}

.confiramtion-input {
    display: block;
    padding: 15px 25px;
    border-radius: 20px;
    background-color: var(--secondary-color);
    font-size: calc(0.77 * var(--size-index));
    color: var(--light-color);
    margin-top: 5px;
}

.confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 50px auto 0;
}

.confirm-container .button {
    margin-top: 25px;
}

input.appeal__value, textarea.appeal__value {
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    resize: vertical;
}

.supply-checkbox {
    font-size: calc(0.77 * var(--size-index));
    color: var(--secondary-color);
}


@media screen and (max-width: 800px) {
    .appeal__inner {
        width: 100%;
    }
}