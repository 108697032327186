.profile {
    margin: 0 0 52px;
}

.profile__inner {
    width: 40%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile__name {
    font-size: calc(0.77 * var(--size-index));
    color: var(--secondary-color);
    font-weight: 400;
    max-width: 40%;
}

.profile__content {
    width: 100%;
    margin: 82px 0;
}

.profile__value {
    font-size: calc(0.77 * var(--size-index));
    font-weight: 600;
    color: var(--secondary-color);
    background-color: transparent;
    text-align: end;
    max-width: 60%;
}

.profile__value:focus {
    outline: none;
}

.profile__field {
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
}

.profile__title {
    text-align: center;
}

.profile__controlls {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 500px;
    width: 60%;
}

.profile__button, .profile__button--center  {
    background-color: var(--primary-color);
    color: var(--light-color);
}

.profile__button--center {
    display: block;
    margin: 0 auto;
}

@media screen and (max-width: 800px) {

    .profile__inner {
        width: 55%;
    }

    .profile__content {
        margin: 40px 0;
    }
}

@media screen and (max-width: 500px) {
    .profile__inner {
        width: 75%;
    }

    .profile__controlls {
        width: 90%;
    }

    .profile__button, .profile__button--center {
        padding: 15px;
    }
}