.home__inner {
    width: 80%;
    margin: 0 auto;
}

.home__paragraph, .home__paragraph--bold {
    font-size: calc(0.8 * var(--size-index));
    text-indent: 30px;
    margin-bottom: 20px;
}

.home__paragraph--bold {
    font-size: calc(0.95 * var(--size-index));
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .home__inner {
        width: 100%;
    }
}