.item, .item--highlight {
    display: flex;
    align-items: center;
    padding: 35px 5px;
    border-bottom: 1px solid var(--border);
    position: relative;
    border-radius: 20px;
}

.item--highlight {
    background-color: var(--error-transparent);
}

.item:last-child, .item--highlight:last-child {
    border-bottom: none;
}

.item__content {
    width: 100%;
    padding-right: 36px;
}

.item__number {
    margin-right: 15px;
    font-size: calc(0.96 * var(--size-index));
    font-weight: 600;
}

.item__controlls,  .item__controlls--spaced {
    margin-left: auto;
    display: flex;
}

.item__button {
    margin-right: 36px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    height: calc(2.6 * var(--size-index));
    width: calc(2.6 * var(--size-index));
    cursor: pointer;
}

.item__button--text {
    margin-right: 36px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    height: calc(2.6 * var(--size-index));
    color: var(--light-color);
    width: max-content;
    padding: 0 calc(1 * var(--size-index));
    font-weight: 400;
    font-size: calc(0.8 * var(--size-index));
    cursor: pointer;
}

.item__button:last-child {
    margin-right: 0;
}

.item__button img {
    width: 70%;
    height: 70%;
}

@media screen and (max-width: 800px) {

    .item, .item--highlight {
        padding: 25px 22px 25px 0;
        border-radius: 10px;
    }

    .item__controlls, .item__controlls--spaced  {
        position: absolute;
        bottom: 10px;
        right: 22px;
    }

    .item__button {
        border-radius: 10px;
    }

    .item__controlls--spaced .item__button {
        margin-right: 0;
    }

    .item__controlls--spaced {
        left: 22px;
        display: flex;
        justify-content: space-between;
    }

    .item__content {
        padding-right: 0;
    }

    .item__number {
        align-self: flex-start;
        margin-top: 15px;
    }
}