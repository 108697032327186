.shadow {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.233);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: calc(8 * var(--size-index));
    height: calc(8 * var(--size-index));
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.loader::before , .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: calc(0.6 * var(--size-index)) solid var(--primary-color);
    animation: prixClipFix 2s linear infinite ;
}

.loader::after{
    border-color: var(--secondary-color);
    animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
    inset: calc(1 * var(--size-index));
}

@keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}