.filledList {
    margin: 0 0 35px;
}

.filled__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.filled__field {
    font-size: calc(0.8 * var(--size-index));
}

.filled__field:first-child {
    max-width: 70%;
}

.filled__button {
    margin: 15px auto;
    display: block;
}

@media screen and (max-width: 800px) {
    .filled__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .filled__field:first-child {
        font-size: calc(1 * var(--size-index));
        margin-bottom: 15px;
        max-width: unset;
    }

    .filled__field {
        font-size: calc(1 * var(--size-index));
    }
}