.alert {
    max-width: 550px;
    width: 50%;
    position: fixed;
    z-index: 50;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 5px 5px 50px 10px rgba(0, 0, 0, 0.25);
    background-color: var(--paper);
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert--warning, .alert--error {
    text-align: center;
    font-size: calc(0.84 * var(--size-index));
}

.alert--error {
    color: var(--error);
}

.alert__body {
    text-align: center;
    margin: 20px 0;
    font-size: calc(0.80 * var(--size-index));
}

.alert__button {
    padding: 10px 20px;
    min-width: unset;
    border-radius: 10px;
}

@media screen and (max-width: 500px) {
    .alert {
        width: 85%;
        padding: 30px;
    }

    .alert--warning, .alert--error {
        font-size: calc(1 * var(--size-index));
    }

    .alert__body {
        margin: 10px 0;
        font-size: calc(0.95 * var(--size-index));
    }
}