.psychologist_statistic {
    width: 70%;
    margin: 48px auto 0;
}

.psychologist_statistic li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.psychologist_statistic p {
    font-weight: 500;
    color: var(--primary-color);
}

.psychologist_statistic .stat_label {
    font-weight: 400;
    color: var(--font-color);
}