.result {
    margin: 50px 0;
}

.result__questions {
    margin-bottom: 48px;
}

.result__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17.5px 0;
}

.result__name {
    width: 60%;
    word-wrap: break-word;
    font-size: calc(0.77 * var(--size-index));
}

.result__value {
    display: block;
    border-radius: 20px;
    word-wrap: break-word;
    width: 25%;
    text-align: center;
    background-color: var(--secondary-color);
    color: var(--light-color);
    font-size: calc(0.77 * var(--size-index));
    padding: 15px 25px;
}

.result__result {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.result__label {
    margin-right: 15px;
    font-size: calc(0.77 * var(--size-index));
}

.result__select {
    padding: 15px 25px;
    border-radius: 20px;
    background-color: var(--secondary-color);
    font-size: calc(0.77 * var(--size-index));
    color: var(--light-color);
}

.result__select:focus {
    outline: none;
}

.result__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-content: center;
    margin-top: 20px;
}


.appeal__controls {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.appeal__controls .simple__field .label {
    width: 40%;
}

.psychologer__container {
    margin-top: 24px;
}

.questionnaireResult__adder {
    background-color: var(--secondary-color);
    border-radius: 20px;
    margin: 0 auto 16px;
}

.appeal__controls .filler__label {
    font-size: calc(0.77 * var(--size-index));
    flex-direction: column;
    align-items: self-start;
    margin: 0;
    width: 30%;
    max-width: 200px;
    min-width: 100px;
}

.appeal__controls .filler__input {
    width: 100%;
}

.appeal__controls .code__label {
    max-width: 280px;
}

.code__hint {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.code__hint img {
    margin-left: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

@media screen and (max-width: 800px) {

    .result__name, .result__value, .result__label, .result__select {
        font-size: calc(0.9 * var(--size-index));
    }

    .result__value {
        padding: 10px 15px;
        border-radius: 10px;
        width: 30%;
    }

    .result__question {
        border-bottom: 1px solid var(--border);
    }

    .result__question:last-child {
        border-bottom: 0;
    }

    .result__questions {
        margin-bottom: 30px;
    }

    .result__container {
        grid-template-columns: repeat(2, 1fr);
    }
}