.place-filler {
    padding: 25px 0 50px;
}

.time-range {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.time-range .appeal__value {
    width: 49%;
}

.place-filler .appeal__value {
    padding: 5px 10px;
    height: 35px;
}