.recordList {
    margin: 0 0 35px;
}

.record__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.record__field {
    font-size: calc(0.8 * var(--size-index));
    word-wrap: break-word;
    max-width: 20%;
    text-align: center;
}

.record__field:first-child {
    max-width: 60%;
    text-align: start;
}

.recordList__searcher {
    position: relative;
    width: 30%;
}

.recordList__input {
    display: block;
    border-radius: 25px;
    background-color: var(--primary-color);
    padding: 14px 30px 14px 62px;
    font-size: calc(0.77 * var(--size-index));
    line-height: 24px;
    width: 100%;
    color: var(--light-color);
}

.recordList__input::placeholder {
    color: rgba(255, 255, 255, 0.61);
}

.recordList__icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
    height: calc(1.4 * var(--size-index));
    width: calc(1.4 * var(--size-index));
}

.record__button {
    margin: 15px auto 0;
    display: block;
    background-color: var(--primary-color);
}

.recordList__controlls {
    display: flex;
    justify-content: space-between;
}

.toggler-container {
    margin: 10px auto 25px;
    width: 25%;
} 


.toggler {
    width: 100%;
    border-radius: 15px;
    background-color: var(--primary-color);
    padding: 5px;
    display: flex;
}

.toggler button {
    border-radius: 15px;
    width: 50%;
    padding: 15px;
    text-align: center;
    background-color: transparent;
    color: var(--light-color);
    font-size: calc(var(--size-index) * 0.8);
}

.toggler button.active {
    background-color: var(--light-color);
    color: var(--font-color);
}

.test-count {
    width: 60%;
    margin: 0 auto 25px;
}

.test-adder {
    margin: 0 auto;
    padding: 11px 20px;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 20px;
    display: flex;
}

@media screen and (max-width: 800px) {
    .record__content {
        display: grid;
        row-gap: 20px;
        padding-bottom: 45px;
        grid-template-columns: repeat(3, 1fr);
    }

    .recordList__controlls {
        align-items: center;
        flex-direction: column;
    }

    .recordList__searcher:first-child {
        margin-bottom: 15px;
    }

    .record__field:first-child {
        font-size: calc(1 * var(--size-index));
        grid-column: 1/4;
        width: 100%;
        text-align: start;
        max-width: unset;
    }

    .record__field {
        width: 100%;
        text-align: center;
        max-width: unset;
    }

    .recordList__searcher {
        width: 50%;
    }

    .recordList__input {
        border-radius: 20px;
        padding: 8px 20px 8px 40px;
        font-size: calc(1 * var(--size-index));
    }

    .record__button {
        margin-top: 30px;
    }

    .recordList__icon {
        left: 10px;
    }
}