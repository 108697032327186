body {
    color: var(--font-color);
    background-color: var(--paper);
    min-height: 100vh;
    padding-bottom: 168px;
    position: relative;
}

* {
    border: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    color: var(--font-color);
}

h2 {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
}

.container {
    max-width: 1200px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

.button {
    font-size: calc(0.77 * var(--size-index));
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 30px;
    color: var(--light-color);
    padding: 22px 30px;
    min-width: 165px;
    cursor: pointer;
}

button:disabled {
    opacity: 0.8;
}

a {
    text-decoration: none;
}

.error {
    color: var(--error);
    font-size: calc(1.2 * var(--size-index));
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin: 15px 0;
}

ul {
    margin-top: 10px;
    text-align: center;
    font-size: calc(1.2 * var(--size-index));
    color: var(--secondary-color);
}

ul * {
    text-align: start;
}

@media screen and (max-width: 800px) {
    h2 {
        font-size: 19px;
    }

    .button {
        min-width: calc(6 * var(--size-index));
        font-size: calc(1.2 * var(--size-index));
        padding: 18px 25px;
        border-radius: 20px;
    }
}