
.custom-select {
    position: relative;
    display: inline-block;
}

.select-list {
    width: 100%;
    margin-top: 0;
    max-height: 350px;
    background-color: var(--secondary-color);
    font-size: calc(0.7 * var(--size-index));
    color: var(--light-color);
    padding: 10px 20px;
    position: absolute;
    overflow: auto;
    list-style: none;
    border-top: none;
    border-radius: 0 0 4px 4px;
    display: none;
    z-index: 2;
}

.select-list li {
    padding: 10px;
    cursor: pointer;
    color: var(--light-color);
}

.select-list li:hover {
    background:  var(--primary-color);;
}

.select-input:focus + .select-list {
    display: block;
}
.select-list:hover {
    display: block;
}