.simple {
    margin: 50px 0 100px;
}

.simple__question {
    width: 55%;
    margin: 0 auto 50px;
    font-size: calc(0.84 * var(--size-index));
    text-align: center;
    word-wrap: break-word;
}

.simple__controlls {
    width: 60%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 15px;
    margin: 0 auto;
}

.simple__check, .simple__check--full {
    display: flex;
    align-items: center;
    user-select: none;
}

.simple__check--full {
    grid-column: 1/4;
}

.simple__frame {
    display: block;
    border: 3px solid var(--secondary-color);
    height: calc(2.5 * var(--size-index));
    width: calc(2.5 * var(--size-index));
    font-size: calc(0.84 * var(--size-index));
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
}

.simple__field, .simple__field--separate {
    display: flex;
    align-items: center;
}

.simple__field .label, .simple__field--separate .label {
    display: block;
    width: 8%;
}

.simple__field--separate {
    margin-top: 16px;
}

.simple__check input:checked + .simple__frame::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 80%;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    background-color: var(--secondary-color);
}

@media screen and (max-width: 800px) {
    .simple__controlls {
        grid-template-columns: repeat(2, 1fr);
    }

    .simple__check--full {
        grid-column: 1/3;
    }
}